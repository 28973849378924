<template>
  <div class="write-container">
    <div class="skeleton-mask" v-if="showSkeleton">
      <div class="skeleton-container">
        <div class="title skeleton-item"></div>
        <div class="description skeleton-item"></div>
        <div class="user">
          <div class="head-img skeleton-item"></div>
          <div class="right">
            <div class="name skeleton-item"></div>
            <div class="info skeleton-item"></div>
          </div>
        </div>
        <div class="content">
          <div class="row skeleton-item"></div>
          <div class="row skeleton-item"></div>
          <div class="row skeleton-item"></div>
          <div class="row skeleton-item"></div>
          <div class="row skeleton-item"></div>
          <div class="row skeleton-item"></div>
        </div>
      </div>
    </div>

    <div v-else>
      <h1>{{ write.title }}</h1>
      <div class="description">{{ write.description }}</div>
      <section class="user">
        <img class="head-img" :src="require('@/assets/image/me.png')" alt="">
        <div class="right">
          <div class="name">{{ write.userId }}</div>
          <div class="info">
            <span class="time">{{ write.createTime | formatTime }}</span>
            <span class="word-length">字数 111</span>
            <!-- <span class="view-num">阅读 223331</span>
            <span class="thumb-num">点赞 12312</span> -->
          </div>
        </div>
      </section>
      <section class="content">
        <div v-for="(item, index) in jsonData" :key="index">
          <el-image
            v-if="item.type === 'image'"
            class="write-img"
            :src="item.src"
            :preview-src-list="[item.src]"
            :lazy="true"
            :style="[{
              ...item.style,
              width: `${item.width > 600 ? 600 : item.width}px`,
              height: `${item.width > 600 ? 600 / item.width * item.height : item.height}px`
            }]"
            alt="">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
              <p>加载失败</p>
            </div>
            </el-image>
          <div
            v-else-if="item.type === 'title'"
            class="write-title"
            :style="{...item.style}"
          >{{ item.content }}</div>
          <div
            v-else-if="item.type === 'section'"
            class="write-section"
            :style="{...item.style}"
          >{{ item.content }}</div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { findWriteUrl } from '@/assets/js/api'
import { Image } from 'element-ui'

export default {
  data() {
    return {
      jsonData: {},
      write: {},
      showSkeleton: true
    }
  },
  components: {
    'el-image': Image
  },
  mounted() {
    this.$axios.post(findWriteUrl, { id: this.$route.params.id })
      .then(res => {
        console.log(res)
        this.write = res.data.data
        this.$axios.get(this.write.filePath).then(resp => {
          this.jsonData = resp.data
          setTimeout(() => {
            this.showSkeleton = false
          }, 1000)
        })
      })
  }
}
</script>
<style lang="less">
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 12px;
  flex-direction: column;

  i {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
</style>

<style lang="less" scoped>
.write-container {
  max-width: 720px;
  padding: 20px;
  margin: 0 auto;

  h1 {
    font-size: 30px;
    font-weight: bold;
    word-break: break-word;
    text-align: left;
  }

  .description {
    margin-top: 30px;
    color: #999999;
    font-size: 14px;
  }

  .user {
    display: flex;
    align-items: center;
    margin-top: 30px;
    letter-spacing: 0px;

    .head-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 40px;
      text-align: left;

      .name {
        font-size: 16px;
      }

      .info {
        font-size: 12px;
        color: #999999;

        span {
          padding-right: 5px;
        }
      }
    }
  }

  .content {
    margin-top: 30px;
    &>div {
      margin: 20px;

      .write-img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .skeleton-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .skeleton-container {
      width: 720px;
      margin: 0 auto;

    }

    .skeleton-item {
      background: linear-gradient(90deg,#f2f2f2 25%,#e6e6e6 37%,#f2f2f2 63%);
      background-size: 400% 100%;
      animation: el-skeleton-loading 1.4s ease infinite;
      border-radius: 4px;
    }

    .title {
      height: 40px;
      width: 320px;
      margin: 20px 0 0;
    }

    .description {
      height: 20px;
      width: 300px;
    }

    .user {
      display: flex;

      .head-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .right {
        .name {
          width: 100px;
          height: 16px;
        }

        .info {
          width: 300px;
          height: 16px;
        }
      }
    }

    .content {
      .row {
        height: 16px;
        margin-top: 30px;
      }
    }
  }
}

@keyframes el-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

</style>